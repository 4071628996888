import { create } from 'zustand';
import { getLatestPresentationId } from '@mentimeter/presentation-state';
import { trackUser } from '@api/tracking/client';

interface HotkeyOverlayStore {
  show: boolean;
  setShow: (show: boolean) => void;
}

export const useHotkeyOverlay = create<HotkeyOverlayStore>((set) => ({
  show: false,
  setShow(show: boolean) {
    set(() => ({ show }));
  },
}));

export const trackToggleHotkeyOverlay = (
  placement: 'Presentation view menu' | 'Hotkey K',
) => {
  trackUser({
    event: 'Toggled hotkey overlay',
    properties: {
      context: 'Presentation view',
      placement,
      'Presentation id': getLatestPresentationId(),
    },
  });
};
