/* eslint-disable menti-react/filename-convention--jsx */
'use client';
import { updatePresentationSlideProxy } from '@mentimeter/compatibility/presentation';
import { getQuestionLayout } from '@mentimeter/editor-essentials/calculations';
import type { Question } from '@mentimeter/http-clients';
import { QuestionProvider as SfinxQuestionProvider } from '@mentimeter/sfinx';

interface PropsT {
  children: React.ReactNode;
  activeQuestion: Question;
}

export const QuestionProvider = ({ children, activeQuestion }: PropsT) => {
  const layout = getQuestionLayout(activeQuestion);
  const updatedSlide = updatePresentationSlideProxy(activeQuestion, {
    layout: {
      $set: layout,
    },
  });

  return (
    <SfinxQuestionProvider value={updatedSlide}>
      {children}
    </SfinxQuestionProvider>
  );
};
