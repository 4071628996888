import { Box, ThemeBgSensitive } from '@mentimeter/ragnar-ui';
import * as React from 'react';
import {
  useHotkeyOverlay,
  usePace,
  useQfa,
  useQuestion,
  useTheme,
} from '../data-mediation';
import { useBlankScreenState } from '../misc/overlays/blank-screen';
import { Next } from './presentation-toolbar/Next';
import { Previous } from './presentation-toolbar/Previous';
import { ControlsWrapper } from './ControlsWrapper';

export const NavigationActions = () => {
  const { background_color } = useTheme();
  const { theme_settings, type } = useQuestion();
  const { enabled: isQfaModalOpen } = useQfa();
  const { state, actions } = usePace();
  if (!state || !actions) {
    throw new Error(
      'tried to render PresentationActions without providing pace data',
    );
  }

  const showHotkeyOverlay = useHotkeyOverlay((state) => state.show);
  const { blankScreenVisible } = useBlankScreenState();

  const disableHotkeys = type !== 'qfa' && isQfaModalOpen;

  if (showHotkeyOverlay || blankScreenVisible) return null;

  return (
    <Box zIndex={5} position="absolute" data-testid="navigation-toolbar">
      <ThemeBgSensitive
        themeBg={theme_settings?.background_color ?? background_color}
      >
        <ControlsWrapper
          show
          extend={({ theme }) => ({
            left: `${theme.kosmosSpacing.space6}px`,
            bottom: `${theme.kosmosSpacing.space6}px`,
          })}
        >
          <Box
            flexDirection="row"
            justifyContent="space-evenly"
            alignItems="center"
            borderRadius="full"
            p="space0.5"
          >
            <Previous
              previous={actions.previous}
              previousSlide={actions.previousSlide}
              previousStep={actions.previousStep}
              hasPreviousSlide={state.hasPreviousSlide}
              hasPrevious={state.hasPrevious}
              hasPreviousStep={state.hasPreviousStep}
              disableHotkeys={disableHotkeys}
            />
            <Next
              next={actions.next}
              nextSlide={actions.nextSlide}
              nextStep={actions.nextStep}
              hasNextSlide={state.hasNextSlide}
              hasNext={state.hasNext}
              hasNextStep={state.hasNextStep}
              disableHotkeys={disableHotkeys}
            />
          </Box>
        </ControlsWrapper>
      </ThemeBgSensitive>
    </Box>
  );
};
