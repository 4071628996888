// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toQueryParam } from '@api/internal';

export interface GetReactionResultsRequest {
  region: 'eu' | 'us';
  userAuth: string;
  slideId: string;
  session?: number;
}

export function getReactionResultsRequest(
  requestParams: GetReactionResultsRequest,
): Request {
  const queryParams = new URLSearchParams();
  if (requestParams.session !== undefined) {
    queryParams.set('session', toQueryParam(requestParams.session));
  }

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/presentation/slide/${requestParams.slideId}/reaction-results?${queryParams.toString()}`;
  return new Request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
