// This file is automatically generated. DO NOT EDIT.

import { getCoreURL } from '@api/internal';

export interface GetSeriesRequest {
  region: 'eu' | 'us';
  seriesId: string;
}

export function getSeriesRequest(requestParams: GetSeriesRequest): Request {
  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/presentation/series/${requestParams.seriesId}`;
  return new Request(url, {
    method: 'GET',
  });
}
