import { resultCacheKey, useData } from '@mentimeter/core-hooks';
import {
  core,
  type QuestionRange,
  type QuestionType,
  type SessionResult,
} from '@mentimeter/http-clients';
import type { QuestionWithSlide } from '@mentimeter/presentation-schema/api-types-overrides';
import { usePresentationRealtimeStore } from '@mentimeter/realtime';
import { useCallback } from 'react';

export interface Result {
  result: SessionResult | undefined;
  range: QuestionRange;
}

const QUESTION_TYPE_WITHOUT_RESULTS: QuestionType[] = [
  'quiz',
  'quiz_open',
  'quiz_leaderboard',
  'slide',
];

const POLLING_INTERVAL = 10000;

interface ActiveResults {
  result: Result['result'];
  isPlaceholderResults: boolean;
  revalidate: () => void;
}
export interface UsePresentationResultsProps {
  legacySeriesId: string | undefined;
  question?: QuestionWithSlide | undefined;
  sessionId: number | 'current';
}

export const usePresentationResults = ({
  legacySeriesId,
  sessionId,
  question,
}: UsePresentationResultsProps): ActiveResults => {
  const connectionState = usePresentationRealtimeStore(
    (state) => state.connectionState,
  );

  const adminKey = question?.legacyQuestionAdminKey ?? question?.admin_key;

  const cacheKey = adminKey ? resultCacheKey(adminKey, sessionId) : undefined;

  const fetcher = useCallback(async () => {
    if (!adminKey) {
      return;
    }
    const { data } = await core().questions.result.get(
      adminKey,
      typeof sessionId === 'number' ? { session: sessionId } : undefined,
    );
    return data;
  }, [sessionId, adminKey]);

  const { data: resultData, revalidate } = useData<SessionResult | undefined>(
    {
      cacheKey,
      seriesId: legacySeriesId,
      skip: Boolean(
        question &&
          Boolean(QUESTION_TYPE_WITHOUT_RESULTS.includes(question.type)),
      ),
      fetcher,
    },
    {
      refreshInterval:
        connectionState === 'failed' || connectionState === 'disconnected'
          ? POLLING_INTERVAL
          : 0,
    },
  );

  const data = resultData ?? question?.result;

  return {
    revalidate,
    result: data,
    isPlaceholderResults: !resultData,
  };
};
