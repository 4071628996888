import { useMemo } from 'react';
import { defaultQfaState } from '@mentimeter/sfinx';
import type { SfinxQfaState } from '@mentimeter/sfinx';
import { useQfaDataSource } from '@mentimeter/core-hooks';
import { useActiveSeriesId } from '@mentimeter/core-hooks/use-ids';
import { core } from '@mentimeter/http-clients';

export const useQfa = (): SfinxQfaState => {
  const seriesId = useActiveSeriesId();
  const { data } = useQfaDataSource({ coreClient: core(), seriesId });
  return useMemo(
    () => ({
      ...defaultQfaState,
      initialized: Boolean(data),
      totalQuestions: data?.ids?.length ?? 0,
      totalUpvotes:
        // @ts-expect-error-auto TS(2532) FIXME: Object is possibly 'undefined'.
        data?.ids?.reduce((prev, id) => prev + data.data[id].upvotes, 0) ?? 0,
    }),
    [data],
  );
};
