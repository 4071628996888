import { getSeriesRequest } from '@core-api/presentation/presentation/series/{series_id}';
import { createPresentationSlideCompatibilityLayer } from '@mentimeter/compatibility/presentation';
import { questionsCacheKey, useData } from '@mentimeter/core-hooks';
import { MentiError } from '@mentimeter/errors/sentry';
import type { Question } from '@mentimeter/http-clients';
import { useCompatibilityQuestionFetcher } from '@mentimeter/migration-utils';
import type { Slide } from '@mentimeter/presentation-schema/api-types-overrides';
import { useCallback, useMemo } from 'react';
import { type SWRConfiguration } from 'swr';
import invariant from 'tiny-invariant';

interface UsePresentationSlidesOptions {
  swrConfig?: SWRConfiguration;
}

/**
 * Fetches a "question with a slide" with backwards compatibility to our legacy domain model.
 * @remarks Accepts a non-authenticated client and it should **only** be used within the presentation app.
 */
export const usePresentationSlides = (
  legacySeriesId: string | undefined,
  options?: UsePresentationSlidesOptions,
) => {
  invariant(
    legacySeriesId,
    'usePresentationSlides(SWR): legacySeriesId is required',
  );
  const cacheKey = questionsCacheKey(legacySeriesId);

  const fetcher = useCompatibilityQuestionFetcher<
    Array<Question> | Array<Slide>
  >(legacySeriesId, getSeriesRequest);

  const {
    data: slides,
    error,
    revalidate,
    lazyData,
  } = useData<Array<Question | Slide>>(
    {
      cacheKey,
      seriesId: legacySeriesId,
      fetcher,
    },
    {
      keepPreviousData: true,
      suspense: true,
      revalidateIfStale: false,
      ...options?.swrConfig,
    },
  );

  if (!slides) {
    throw new MentiError(`Series response was empty`, { feature: 'live' });
  }

  const wrappedLazyData = useCallback(() => {
    const lazySlides = lazyData();
    return lazySlides?.map((question) =>
      createPresentationSlideCompatibilityLayer(question),
    );
  }, [lazyData]);

  return useMemo(() => {
    return {
      slides: slides
        ?.map((slide) => createPresentationSlideCompatibilityLayer(slide))
        ?.filter((slide) => !slide.skip_slide),
      error,
      revalidate,
      lazyGetSlides: wrappedLazyData,
    };
  }, [slides, error, revalidate, wrappedLazyData]);
};
