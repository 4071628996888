import { userCache } from '@mentimeter/user';
import type { Regions } from '@mentimeter/http-clients';
import type { GetSeriesRequest } from '@core-api/presentation/presentation/series/{series_id}';
export type { getSeriesResponse } from '@core-api/editor/editor/series/{series_id}';

interface BaseApiRequestParams {
  /**
   * Providing a region is only necessary for requests that
   * could be made in a different region than the user's region.
   *
   * @default
   * Region from `userCache`.
   *
   * @example
   * An update to a series in `eu` from a user in `us`.
   */
  region: Regions;

  /**
   * @default
   * Token from `userCache`.
   */
  userAuth: string;
}

type RequestWithRequiredRegionParameter = GetSeriesRequest;

type RegionParameter<Params> = Params extends RequestWithRequiredRegionParameter
  ? Required<Pick<BaseApiRequestParams, 'region'>>
  : /**
     * In most cases, `region` doesn't need to be provided.
     */
    Partial<Pick<BaseApiRequestParams, 'region'>>;

type ApiRequest<Params> = (params: Params & BaseApiRequestParams) => Request;

export type ApiRequestParams<Params> = Omit<
  Parameters<ApiRequest<Params>>[0],
  keyof BaseApiRequestParams
> &
  RegionParameter<Params>;

export function makeRequest<Params>(
  request: ApiRequest<Params>,
  params: ApiRequestParams<Params>,
) {
  const userAuth = userCache.getToken();
  const { region } = userCache;

  return request({
    region,
    ...params,
    userAuth,
  } as Parameters<typeof request>[0]);
}
