import { ASCII_EMOJI_CODE } from 'features/core/edit_view/constants/profanity';
import axios from 'axios';
import useSWR from 'swr';

export const fetchProfanities = async (lang: string): Promise<string[]> => {
  try {
    const params = { lang };
    const { data } = await axios.get(`/app/presentation/api/profanity`, {
      params,
    });
    return data;
  } catch {
    // todo: error handling?
    return [];
  }
};

export const useProfanities = (profanityLang: string) => {
  const { data: profanities = [] } = useSWR(
    `/app/presentation/api/profanity?${profanityLang}`,
    async () => {
      if (!profanityLang) return [];
      const profanityLangs = profanityLang
        .split(',')
        .filter((l) => l !== ASCII_EMOJI_CODE);
      // Re-add profanities when settings change
      const promises = profanityLangs.map(fetchProfanities);
      return Promise.all(promises).then((p) => p.flatMap((res) => res));
    },
  );

  return { profanities };
};
