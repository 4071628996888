import { usePresentation } from './state';

export const usePresentationPublic = () => {
  const presentationState = usePresentation();

  if (presentationState.state) {
    const { hasReachedEnd, totalSlides, slideIndex } = presentationState.state;
    const isOnLastSlide = slideIndex === totalSlides - 1;
    // Not showing end screen in public view
    if (!hasReachedEnd && isOnLastSlide) {
      return {
        ...presentationState,
        state: {
          ...presentationState.state,
          hasNextSlide: false,
        },
      };
    }
  }
  return presentationState;
};
