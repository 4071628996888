import * as React from 'react';
import type { ClickableT } from '@mentimeter/ragnar-ui';
import { Box, Clickable } from '@mentimeter/ragnar-ui';
import type { IconT } from '@mentimeter/ragnar-visuals';
import { px } from '@mentimeter/ragnar-utils';
import type { HotkeyCode } from '../misc/hotkeys/state/types';
import { Hotkey } from '../misc/hotkeys/Hotkey';
import { useConfig } from '../misc/config';
import Tooltip from './Tooltip';

interface ToolbarItemT extends ClickableT {
  icon: React.ComponentType<IconT>;
  label: string;
  enableHotkeys?: boolean;
  hotkey?: HotkeyCode | undefined;
  onHotkey?: () => void;
  onClick?: () => void;
  ariaLabel: string;
  id: string;
  disabled?: boolean;
  richTooltipText?: string;
  richTooltipImage?: React.ComponentType<any>;
  placement?: 'top' | 'right';
  hideTooltip?: boolean;
}

const PORTAL_ID = 'toolbar-portal-';

export const ToolbarButton = React.forwardRef(
  (
    {
      icon: I,
      label,
      hotkey,
      onClick,
      onHotkey,
      ariaLabel,
      id,
      disabled,
      richTooltipText,
      richTooltipImage,
      placement = 'top',
      hideTooltip,
      ...rest
    }: ToolbarItemT,
    ref,
  ) => {
    const { enableHotkeys } = useConfig();

    return (
      <Box
        id={id}
        ref={ref}
        alignItems="center"
        flexDirection={placement === 'top' ? 'column' : 'row'}
      >
        {enableHotkeys && hotkey && onHotkey && !disabled && (
          <Hotkey keyCode={hotkey} action={onHotkey} />
        )}

        {placement === 'top' && <Box id={`${PORTAL_ID}${id}`} />}

        <Clickable
          {...rest}
          disabled={disabled}
          onClick={onClick}
          flexDirection={placement === 'right' ? 'row' : 'column'}
          aria-label={ariaLabel}
          alignItems="center"
          justifyContent="center"
          borderRadius="full"
          color={rest?.['aria-pressed'] ? 'onPrimary' : 'primary'}
          width={52}
          height={52}
          extend={({ theme }) => ({
            pointerEvents: disabled ? 'none' : 'auto',
            transition: `padding ${theme.durations[0]}s ease-in-out`,
            ...(!disabled && {
              '@media (hover: hover)': {
                ':hover': {
                  opacity: 1,
                  '&:not(:active) svg': {
                    transform: 'scale(1.16)',
                  },
                  '[aria-pressed=true] > div': {
                    backgroundColor: theme.colors.buttonBgPrimaryHover,
                  },
                  ':not([aria-pressed=true]) > div': {
                    backgroundColor:
                      theme.colors.surfaceOverlayTransparentHover,
                  },
                },
              },
              ':focus-visible': {
                '> div': {
                  backgroundColor: theme.colors.surfaceOverlayTransparentHover,
                },
                '& svg': {
                  transform: 'scale(1.16)',
                },
                '[aria-pressed=true]': {
                  color: theme.colors.onPrimary,
                  '> div': {
                    backgroundColor: theme.colors.buttonBgPrimary,
                  },
                },
              },
              ':active': {
                opacity: 1,
                padding: px(3),
              },
              '[aria-pressed=true]': {
                color: theme.colors.onPrimary,
                '> div': {
                  backgroundColor: theme.colors.buttonBgPrimary,
                },
              },
            }),
            ':disabled': {
              opacity: 0.4,
              color: theme.colors.primary,
            },
          })}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            p="space3"
            borderRadius="full"
            width="100%"
            height="100%"
          >
            <Box
              maxWidth={24}
              maxHeight={24}
              extend={({ theme }) => ({
                '> svg': {
                  transition: `transform ${theme.durations[0]}s ease-in-out`,
                },
              })}
            >
              <I size="100%" color="inherit" />
            </Box>
          </Box>
        </Clickable>

        {!hideTooltip && (
          <Tooltip
            placement={placement}
            id={id}
            label={label}
            hotkey={enableHotkeys ? hotkey : undefined}
            richTooltipText={richTooltipText}
            richTooltipImage={richTooltipImage}
            portalId={PORTAL_ID}
          />
        )}

        {placement === 'right' && <Box id={`${PORTAL_ID}${id}`} />}
      </Box>
    );
  },
);
