import type { Question } from '@mentimeter/http-clients';
import type { QuestionWithSlide as PresentationQuestionWithSlide } from '@mentimeter/presentation-schema/api-types-overrides';
import update, { type CustomCommands, type Spec } from 'immutability-helper';
import { isProxyInstance } from '../../../utils/is-proxy-instance';
import { createPresentationSlideCompatibilityLayer } from './create-presentation-slide-compatibility-layer';

export const updatePresentationSlideProxy = <
  T extends Question | PresentationQuestionWithSlide,
  R = T | undefined,
>(
  source: R,
  spec: Spec<T, CustomCommands<T>>,
): R => {
  if (!source) return undefined as R;

  let questionData: Question = source as unknown as T;

  if (isProxyInstance(source)) {
    questionData = (source as unknown as { __target: Question }).__target;
  }

  return createPresentationSlideCompatibilityLayer(
    update(questionData, spec as any),
  ) as R;
};
