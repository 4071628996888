'use client';

import type { SeriesWithSlideDeck as Series } from '@mentimeter/presentation-schema/api-types-overrides';
import { usePresentationResults } from '@mentimeter/presentation-data-hooks';
import { usePresentationSlides } from '@mentimeter/presentation-data-hooks/slide';
import type { PresentationMode } from '@mentimeter/presentation-url-utils';
import { ControlContextProvider } from '@mentimeter/question-modules-contexts';
import {
  PresentationProvider,
  PaceProvider as PublicPaceProvider,
  QfaProvider,
  QuestionResultsProvider,
  ReactionsResultsProvider,
  SeriesProvider,
  ThemeProvider,
  TranslationProvider,
} from '@mentimeter/sfinx';
import { useAppSelector } from 'features/core/editor/state';
import { useQfa } from 'features/core/presentation/public/useQfa';
import { usePresentationReactionResultsCompatibility } from 'features/core/presentation/shared/compatibility/slide/use-presentation-reactions-results-compatibility';
import {
  getTranslateHelper,
  useInitTranslations,
} from 'features/core/presentation/shared/InitTranslations';
import { useSyncPresentStateAndURL } from 'features/core/presentation/shared/pace/useSyncPresentStateAndURL';
import { ProfanitiesProvider } from 'features/core/presentation/shared/profanities';
import { QuestionProvider } from 'features/core/presentation/shared/question';
import { QuizResultProvider } from 'features/core/presentation/shared/quiz-module/QuizResultProvider';
import SegmentationProvider from 'features/core/presentation/shared/segmentation';
import { SplitProvider } from 'features/core/presentation/shared/split';
import * as React from 'react';
import { usePublicPresentationState } from './usePublicPresentationState';

interface Props {
  series: Series;
  questionId: string;
  mode: PresentationMode;
  children: React.ReactNode;
}

export const PresentationContainer = ({
  series,
  questionId,
  mode,
  children,
}: Props) => {
  const {
    segmentations,
    profanity_lang: profanityLang,
    id: seriesId,
    closed_for_voting,
    vote_key,
    theme,
    language,
    reactions,
  } = series;
  const { slides } = usePresentationSlides(seriesId);

  const slidePublicKeyToInitWith = slides.find(
    (q) => q.id === questionId,
  )?.public_key;

  const presentationStore = usePublicPresentationState({
    slidePublicKey: slidePublicKeyToInitWith,
    series,
  });

  const activeQuestion = slides.find((q) => {
    return q.public_key === presentationStore?.state?.slidePublicKey;
  });

  useSyncPresentStateAndURL({
    mode,
    questions: slides,
    setActiveSlide: presentationStore.actions.setActiveSlide,
  });

  const translate = useAppSelector((state) => getTranslateHelper(state.locale));

  const { result, isPlaceholderResults } = usePresentationResults({
    question: activeQuestion,
    sessionId: 'current',
    legacySeriesId: seriesId,
  });
  const questionResults = React.useMemo(
    () => (result ? { ...result, isPlaceholderResults } : undefined),
    [result, isPlaceholderResults],
  );
  const reactionsResults =
    usePresentationReactionResultsCompatibility(activeQuestion);

  const qfa = useQfa();

  useInitTranslations(language);

  if (!questionId || !presentationStore.state || !activeQuestion) {
    return null;
  }

  return (
    <ControlContextProvider>
      <PublicPaceProvider value={presentationStore}>
        <QuestionProvider activeQuestion={activeQuestion}>
          <SegmentationProvider
            questions={slides}
            activeQuestionId={activeQuestion.id}
            segmentations={segmentations}
          >
            <SeriesProvider
              value={{
                vote_key,
                id: seriesId,
                closed_for_voting,
                qfa_active: false,
                comments_enabled: false,
                qfa_intercom_enabled: false,
                qfa_moderation_enabled: false,
                reactions,
                participation_identity_mode: 'anonymous',
              }}
            >
              <PresentationProvider
                value={{
                  showTrends: false,
                }}
              >
                <SplitProvider>
                  <QuestionResultsProvider value={questionResults}>
                    <ReactionsResultsProvider value={reactionsResults?.results}>
                      <QuizResultProvider
                        type={activeQuestion.type}
                        questionId={activeQuestion.id}
                        choices={activeQuestion.choices}
                        isTestVotesEnabled={false}
                      >
                        <TranslationProvider translate={translate}>
                          <QfaProvider value={qfa}>
                            <ThemeProvider
                              presentationTheme={theme}
                              slideTheme={
                                activeQuestion.override_theme
                                  ? activeQuestion.theme_settings
                                  : undefined
                              }
                            >
                              <ProfanitiesProvider
                                profanityLang={profanityLang}
                              >
                                {children}
                              </ProfanitiesProvider>
                            </ThemeProvider>
                          </QfaProvider>
                        </TranslationProvider>
                      </QuizResultProvider>
                    </ReactionsResultsProvider>
                  </QuestionResultsProvider>
                </SplitProvider>
              </PresentationProvider>
            </SeriesProvider>
          </SegmentationProvider>
        </QuestionProvider>
      </PublicPaceProvider>
    </ControlContextProvider>
  );
};
