import {
  isLinkupEnvironment,
  clientSideReplaceLinkupSubdomain,
} from '@mentimeter/tracing';

const VOTING_URL = process.env.NEXT_PUBLIC_VOTING_URL as string;

export const getVotingUrl = () =>
  isLinkupEnvironment()
    ? clientSideReplaceLinkupSubdomain(VOTING_URL)
    : VOTING_URL;
