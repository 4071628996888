import {
  getReactionResultsRequest,
  getReactionResultsResponse,
  type GetReactionResultsRequest,
} from '@core-api/presentation/presentation/slide/{slide_id}/reaction-results';
import { useData } from '@mentimeter/core-hooks';
import { MentiError } from '@mentimeter/errors/sentry';
import {
  core,
  type Reaction,
  type ReactionsResults,
} from '@mentimeter/http-clients';
import { useNewEndpoints } from '@mentimeter/migration-utils';
import { getRegionBySeriesId } from '@mentimeter/region';
import userCache from '@mentimeter/user';
import { useCallback } from 'react';
import type { SWRConfiguration } from 'swr';
import invariant from 'tiny-invariant';
import { makeRequest } from '../../request-wrapper/make-request';

interface UsePresentationReactionResultsOptions {
  swrConfig?: SWRConfiguration;
  getReactionResultsCacheKey: (legacyQuestionId: string) => string;
}
interface UsePresentationReactionResultsArgs {
  legacySeriesId: string;
  legacyQuestionIdOrSlideId: string | undefined;
  session: 'current' | number;
  isMigrated: boolean;
}

export const usePresentationReactionResults = (
  {
    legacySeriesId,
    legacyQuestionIdOrSlideId,
    session,
    isMigrated,
  }: UsePresentationReactionResultsArgs,
  config: UsePresentationReactionResultsOptions,
) => {
  invariant(
    legacySeriesId,
    'usePresentationReactionResults(SWR): legacySeriesId is required',
  );
  const cacheKey = legacyQuestionIdOrSlideId
    ? config.getReactionResultsCacheKey(legacyQuestionIdOrSlideId)
    : null;

  const newEndpointsEnabled = useNewEndpoints() && isMigrated;

  const fetcher = useCallback(async () => {
    const region = getRegionBySeriesId(legacySeriesId);

    invariant(
      legacyQuestionIdOrSlideId,
      'usePresentationReactionResults(SWR): legacyQuestionId is required',
    );

    if (newEndpointsEnabled) {
      const userAuth = userCache.getToken();
      if (!userAuth) {
        return {
          respondents: 0,
          results: {},
          total: { amount: 0 },
          reactions: [],
        } as ReactionsResults;
      }

      const payload: Partial<GetReactionResultsRequest> = {
        region,
        slideId: legacyQuestionIdOrSlideId,
      };
      if (session !== 'current') {
        payload['session'] = session;
      }
      const response = await fetch(
        makeRequest(
          getReactionResultsRequest,
          payload as GetReactionResultsRequest,
        ),
      );
      if (response.ok) {
        const results = await getReactionResultsResponse(response);
        return {
          respondents: results.total.amount,
          results: results.reactions.reduce<Record<Reaction, number>>(
            (acc, reaction) => {
              acc[reaction.reaction] ??= 0;
              acc[reaction.reaction] += reaction.amount;
              return acc;
            },
            {} as Record<Reaction, number>,
          ),
          total: results.total,
          reactions: results.reactions,
        } as ReactionsResults;
      } else {
        throw new MentiError('Failed to fetch reaction results', {
          feature: 'compatibility-layer',
        });
      }
    } else {
      const { data } = await core().presentation.reactions.results.get(
        legacyQuestionIdOrSlideId,
      );
      return data;
    }
  }, [legacySeriesId, newEndpointsEnabled, legacyQuestionIdOrSlideId, session]);

  return useData<ReactionsResults | undefined>(
    {
      cacheKey: cacheKey ?? '',
      seriesId: legacySeriesId,
      fetcher,
      skip: !legacyQuestionIdOrSlideId,
    },
    { keepPreviousData: true, ...config?.swrConfig },
  );
};
