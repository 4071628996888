import type { Question } from '@mentimeter/http-clients';
import { useParams, useSearchParams } from '@mentimeter/next-navigation';
import {
  subscribeToPresentationStore,
  type PresentationStore,
  usePresentation,
} from '@mentimeter/presentation-state';
import {
  buildPresentationPath,
  parsePresentationSession,
  type PresentationMode,
} from '@mentimeter/presentation-url-utils';
import * as React from 'react';

export const useSyncPresentStateAndURL = ({
  questions,
  setActiveSlide,
  mode,
}: {
  questions: Question[];
  setActiveSlide: PresentationStore['actions']['setActiveSlide'];
  mode: PresentationMode;
}) => {
  const params = useParams<{ seriesId: string }>();
  const searchParams = useSearchParams();

  const seriesId = params?.seriesId;
  const questionId = searchParams?.get('question');
  const session = parsePresentationSession(searchParams?.get('session'));

  // Aggregate question public keys and ids into strings for more stable memoization
  const questionPublicKeys = questions
    .map((question) => question.public_key)
    .join(',');
  const questionIds = questions.map((question) => question.id).join(',');

  const questionMap = React.useMemo(() => {
    // Map question public keys to question ids. This will ONLY re-evaluate if the number of questions changes, or if the order of questions changes.
    // This ensures that we don't get incessant invocations of the useEffect below, since the callback it depends on will not update as often
    const ids = questionIds.split(',');
    return questionPublicKeys
      .split(',')
      .reduce<Record<string, string>>((acc, publicKey, index) => {
        acc[publicKey] = ids[index] as string;
        return acc;
      }, {});
  }, [questionIds, questionPublicKeys]);

  const isUpdatingURL = React.useRef(false);
  const updatingUrlTimer = React.useRef<NodeJS.Timeout | null>(null);

  const stateIsInitialized = usePresentation(
    (state) => state.state !== undefined && state.internalActions !== undefined,
  );

  const handleUrlUpdate = React.useCallback(
    (questionId: string) => {
      const [publicKey] =
        Object.entries(questionMap).find(([_, id]) => questionId === id) || [];
      if (!publicKey) return;
      if (!stateIsInitialized) return;

      setActiveSlide(publicKey);
    },
    [questionMap, setActiveSlide, stateIsInitialized],
  );

  const updateUrl = React.useCallback(
    (publicKey: string) => {
      if (questionMap[publicKey] && seriesId) {
        const presentationPath = buildPresentationPath({
          seriesId,
          questionId: questionMap[publicKey],
          mode,
          session,
          currentSearchParams: searchParams,
        });
        window.history.pushState(null, '', presentationPath);
      }
    },
    [questionMap, seriesId, mode, session, searchParams],
  );

  /**
   * Subscribe to URL updates
   */
  React.useEffect(() => {
    if (!questionId) return;
    if (isUpdatingURL.current) {
      if (updatingUrlTimer.current) {
        // If true, we have recently updated the URL from the pres state, so we can ignore this update. Clear the timer
        // and reset the flag
        clearTimeout(updatingUrlTimer.current);
        updatingUrlTimer.current = null;
      }
      isUpdatingURL.current = false;
      return;
    }

    handleUrlUpdate(questionId);
  }, [handleUrlUpdate, questionId]);

  /**
   * Update URL when active slide changes
   */
  React.useEffect(() => {
    const unsubscribe = subscribeToPresentationStore(
      (store) => store.state?.slidePublicKey,
      (slidePublicKey) => {
        if (!slidePublicKey) return;

        if (updatingUrlTimer.current) {
          // If we have an active timer, we need to dismiss it and start a new
          clearTimeout(updatingUrlTimer.current);
          updatingUrlTimer.current = null;
        }

        isUpdatingURL.current = true;
        updateUrl(slidePublicKey);
        updatingUrlTimer.current = setTimeout(() => {
          // In order to not block ourselves if something goes wrong, we reset the flag after a reasonable timeout
          isUpdatingURL.current = false;
        }, 250);
      },
    );

    return () => {
      unsubscribe();
    };
  }, [updateUrl]);
};
