/* eslint-disable menti-react/filename-convention--jsx */
import { ProfanitiesProvider as SfinxProfanitiesProvider } from '@mentimeter/sfinx';
import { useProfanities } from './hooks';

interface ProfanitiesProviderProps {
  children?: React.ReactNode;
  profanityLang: string;
}

export const ProfanitiesProvider = ({
  children,
  profanityLang,
}: ProfanitiesProviderProps) => {
  const { profanities } = useProfanities(profanityLang);

  return (
    <SfinxProfanitiesProvider
      value={{
        profanities,
      }}
    >
      {children}
    </SfinxProfanitiesProvider>
  );
};
