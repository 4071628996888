import * as React from 'react';
import { ArrowLeftIcon } from '@mentimeter/ragnar-visuals';
import type { Placement } from '@mentimeter/presentation-state';
import type { ClickableT } from '@mentimeter/ragnar-ui';
import { Hotkey } from '../../misc/hotkeys/Hotkey';
import { ToolbarButton } from '../ToolbarItem';
import { useTranslate } from '../../../state/translation';

interface PreviousProps extends ClickableT {
  previous: (placement: Placement) => void;
  previousSlide: (placement: Placement) => void;
  previousStep: (placement: Placement) => void;
  hasPreviousSlide: boolean;
  hasPrevious: boolean;
  hasPreviousStep: boolean;
  disableHotkeys?: boolean;
}

export const Previous = ({
  previous,
  previousSlide,
  previousStep,
  hasPrevious,
  hasPreviousSlide,
  hasPreviousStep,
  disableHotkeys = false,
  ...rest
}: PreviousProps) => {
  const translate = useTranslate();

  return (
    <>
      <ToolbarButton
        {...rest}
        ariaLabel={translate('toolbar.previous_slide')}
        id="previous-slide"
        data-testid="toolbar-previous"
        icon={ArrowLeftIcon}
        disabled={!hasPreviousSlide}
        label={translate('toolbar.previous_slide')}
        onClick={() => previousSlide('Navigation button')}
      />
      {!disableHotkeys && hasPrevious && (
        <>
          <Hotkey
            preventDefault
            keyCode="pageup"
            action={() => previous('Hotkey pageup')}
          />
          <Hotkey
            preventDefault
            keyCode="arrowleft"
            action={() => previous('Hotkey arrowleft')}
          />
          {hasPreviousStep && (
            <Hotkey
              preventDefault
              keyCode="arrowup"
              action={() => previousStep('Hotkey arrowup')}
            />
          )}
        </>
      )}
    </>
  );
};
