/* eslint-disable menti-react/filename-convention--jsx */
import type { Question } from '@mentimeter/http-clients';
import { SegmentationProvider } from '@mentimeter/sfinx';
import * as React from 'react';

interface SegmentableQuestion {
  id: string;
  title: string;
}

const filterSegmentableSlides = (
  questions: Question[],
  currentId: string | undefined,
): SegmentableQuestion[] =>
  questions
    .filter((q) => {
      const conditions = [
        q.id !== currentId || 'n/a This is the Current Question',
        q.type === 'choices' ||
          q.type === 'choices_images' || // legacy
          'n/a - Not a Multiple Choice slide',
        (q.choices && q.choices.length < 11) ||
          'n/a Has more than 10 alternatives',
        (q.choices && q.choices.length !== 0) || 'n/a Has 0 alternatives',
        q.max_votes === 1 || 'n/a More than one answer is enabled',
      ];
      if (conditions.every((c) => c === true)) return true;
      return false;
    })
    .map((q) => ({ id: q.id, title: q.question }));

export const useGetSegmentationValues = ({
  questions,
  activeQuestionId,
  segmentations,
}: {
  questions: Array<Question>;
  activeQuestionId: string;
  segmentations: Record<string, string> | undefined;
}) => {
  const segmentBy = segmentations?.[activeQuestionId];

  const segmentedBy = segmentBy
    ? questions.find((q) => q.id === segmentBy)
    : undefined;

  const segmentableQuestions = React.useMemo(
    () => filterSegmentableSlides(questions, activeQuestionId),
    [activeQuestionId, questions],
  );

  return React.useMemo(
    () => ({ segmentedBy, segmentableQuestions }),
    [segmentableQuestions, segmentedBy],
  );
};

interface SegmentationProps {
  children: React.ReactNode;
  questions: Array<Question>;
  activeQuestionId: string;
  segmentations: Record<string, string> | undefined;
}

const Segmentation = ({
  children,
  questions,
  activeQuestionId,
  segmentations,
}: SegmentationProps) => {
  const { segmentedBy, segmentableQuestions } = useGetSegmentationValues({
    questions,
    activeQuestionId,
    segmentations,
  });
  return (
    <SegmentationProvider
      value={{
        segmentedBy,
        segmentableQuestions,
      }}
    >
      {children}
    </SegmentationProvider>
  );
};

export default Segmentation;
