import * as React from 'react';
import type { DefaultValidKeyCodesT } from '@mentimeter/hotkeys';
import { HotkeyHandler, filter } from '@mentimeter/hotkeys';
import type { HotkeyActionT } from '@mentimeter/question-modules-types';
import { useConfig } from '../../data-mediation';

export const Hotkey = ({
  keyCode,
  action,
  preventDefault = false,
  conflictingKeys,
  onKeyUp,
  onKeyDown,
}: {
  keyCode: DefaultValidKeyCodesT;
  action: HotkeyActionT;
  onKeyUp?: HotkeyActionT;
  onKeyDown?: HotkeyActionT;
  preventDefault?: boolean;
  conflictingKeys?: DefaultValidKeyCodesT[] | undefined;
}) => {
  const { enableHotkeys } = useConfig();
  const handler = React.useCallback(() => {
    action(keyCode);
  }, [action, keyCode]);

  if (!enableHotkeys) return null;

  return (
    <HotkeyHandler
      onKeyDown={(event) => {
        if (filter.inputIsFocused(event)) return;
        if (preventDefault) {
          event.preventDefault();
        }
        onKeyDown?.(keyCode);
      }}
      onKeyUp={(event) => {
        if (filter.inputIsFocused(event)) return;
        if (preventDefault) {
          event.preventDefault();
        }
        onKeyUp?.(keyCode);
        handler();
      }}
      keyCode={keyCode}
      conflictingKeys={conflictingKeys}
    />
  );
};
