import type { Question } from '@mentimeter/http-clients';
import type { SeriesWithSlideDeck as Series } from '@mentimeter/presentation-schema/api-types-overrides';
import { usePresentationSlides } from '@mentimeter/presentation-data-hooks/slide';
import {
  usePresentationPublic,
  type PresentationStore,
} from '@mentimeter/presentation-state';
import * as React from 'react';

export const usePublicPresentationState = ({
  slidePublicKey,
  series,
}: {
  slidePublicKey?: Question['public_key'] | undefined;
  series: Series;
}): PresentationStore => {
  const { slides } = usePresentationSlides(series.id);
  const presentationState = usePresentationPublic();
  const { create } = presentationState.actions;

  const seriesRef = React.useRef({ ...series, questions: slides });
  const publicKeyRef = React.useRef(slidePublicKey);

  // Initiate presentation state only once
  React.useEffect(() => {
    create(seriesRef.current, publicKeyRef.current);
  }, [create]);

  return presentationState;
};
