import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { useOptionalPace } from '../data-mediation';

export interface ProgressIndicatorT {
  progress: number;
}

const HEIGHT = '3px';

const C = React.memo<ProgressIndicatorT>(({ progress }) => {
  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      flex={0}
      width="100%"
      height={HEIGHT}
      bg="bg"
      zIndex={100}
      extend={({ theme }) => ({
        transition: `background ${theme.durations[2]}s ease`,
      })}
    >
      <Box
        height={HEIGHT}
        bg="text"
        width="100%"
        extend={({ theme }) => ({
          transition: `all ${theme.durations[2]}s ease`,
          transform: `translateX(${-100 + progress * 100}%)`,
        })}
      />
    </Box>
  );
});

const ProgressIndicator = () => {
  const progress = useOptionalPace()?.selectors?.progress() ?? 0;
  return <C progress={progress} />;
};

export default ProgressIndicator;
