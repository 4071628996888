import * as React from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui';
import { Box } from '@mentimeter/ragnar-ui';

export const ControlsWrapper = ({
  children,
  show,
  transformOrigin,
  transform,
  ...rest
}: BoxT & {
  show: boolean;
  transformOrigin?: string;
  transform?: string;
}) => {
  return (
    <Box
      position="fixed"
      data-testid="controls-container"
      borderRadius="full"
      color="primary"
      {...rest}
      extend={({ theme }) => ({
        transition: `all ${theme.durations[1]}s ease`,
        opacity: show ? 1 : 0,
        pointerEvents: show ? 'auto' : 'none',
        transformOrigin,
        transform,
        backdropFilter: theme.kosmosBlur.xl,
        // WebkitBackdropFilter: theme.kosmosBlur.xl, // The positioning in DropdownMenu -> Radix -> Floating UI is not working in Safari 17.5 in combination with WebkitBackdropFilter
        backgroundColor: theme.colors.surfaceOverlayTransparent,
        ...rest?.extend?.({ theme }),
      })}
    >
      {children}
    </Box>
  );
};
