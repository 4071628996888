import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import type { QuestionType } from '@mentimeter/http-clients';
import type { Placement } from '@mentimeter/presentation-state';
import { usePace, useQuestion } from '../data-mediation';

const SWIPE_RIGHT = 'swipe-right';
const SWIPE_LEFT = 'swipe-left';
const THRESHOLD = 50;

interface GesturesT {
  children: React.ReactNode;
  hasNextStep: boolean;
  hasNextSlide: boolean;
  hasPreviousSlide: boolean;
  nextStep: (placement: Placement) => void;
  nextSlide: (placement: Placement) => void;
  previousSlide: (placement: Placement) => void;
  type: QuestionType;
}

export const C = React.memo<GesturesT>(
  ({
    nextStep,
    nextSlide,
    previousSlide,
    hasNextStep,
    hasNextSlide,
    hasPreviousSlide,
    type,
    children,
  }: GesturesT) => {
    const startX = React.useRef(0);
    const currentX = React.useRef(0);

    const onClick = React.useCallback(() => {
      if (hasNextStep && type !== 'open') {
        nextStep('Gesture click');
      }
    }, [hasNextStep, nextStep, type]);

    const updateSlide = React.useCallback(
      (direction: typeof SWIPE_RIGHT | typeof SWIPE_LEFT) => {
        switch (direction) {
          case SWIPE_LEFT:
            if (hasNextSlide) {
              nextSlide('Gesture swipe');
            }
            break;
          case SWIPE_RIGHT:
            if (hasPreviousSlide) {
              previousSlide('Gesture swipe');
            }
            break;
          default:
            break;
        }
      },
      [hasNextSlide, hasPreviousSlide, nextSlide, previousSlide],
    );

    const touchStart = React.useCallback((e: React.TouchEvent<HTMLElement>) => {
      const touch = e.touches[0];
      if (touch) {
        const { clientX } = touch;
        startX.current = clientX;
        currentX.current = clientX;
      }
    }, []);

    const touchMove = React.useCallback((e: React.TouchEvent<HTMLElement>) => {
      const touch = e.touches[0];
      if (touch) {
        const { clientX } = touch;
        currentX.current = clientX;
      }
    }, []);

    const touchEnd = React.useCallback(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      Math.abs(startX.current - currentX.current) > THRESHOLD &&
        (startX.current < currentX.current
          ? updateSlide(SWIPE_RIGHT)
          : updateSlide(SWIPE_LEFT));
    }, [updateSlide]);

    return (
      <Box
        width="100%"
        height="100%"
        position="absolute"
        onTouchStart={touchStart}
        onTouchMove={touchMove}
        onTouchEnd={touchEnd}
        onClick={onClick}
      >
        {children}
      </Box>
    );
  },
);

/**
 * This wrapper component adds touch and click events to the presentation view
 * to enable users to click to increment steps and swipe between slides on mobile
 */
const Gestures = ({ children }: { children: React.ReactNode }) => {
  const { state, actions } = usePace();
  if (!state || !actions) {
    throw new Error(
      'tried to render PresentationActions without providing pace data',
    );
  }
  const { type } = useQuestion();
  return (
    <C
      hasNextStep={state.hasNextStep}
      hasNextSlide={state.hasNextSlide}
      hasPreviousSlide={state.hasPreviousSlide}
      nextStep={actions.nextStep}
      nextSlide={actions.nextSlide}
      previousSlide={actions.previousSlide}
      type={type}
    >
      {children}
    </C>
  );
};

export default Gestures;
