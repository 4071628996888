import * as React from 'react';
import { ArrowRightIcon } from '@mentimeter/ragnar-visuals';
import { type Placement } from '@mentimeter/presentation-state';
import type { ClickableT } from '@mentimeter/ragnar-ui';
import { Hotkey } from '../../misc/hotkeys/Hotkey';
import { ToolbarButton } from '../ToolbarItem';
import { useTranslate } from '../../../state/translation';

interface NextProps extends ClickableT {
  next: (placement: Placement) => void;
  nextSlide: (placement: Placement) => void;
  nextStep: (placement: Placement) => void;
  hasNextSlide: boolean;
  hasNext: boolean;
  hasNextStep: boolean;
  disableHotkeys?: boolean;
}

export const Next = ({
  next,
  nextSlide,
  nextStep,
  hasNext,
  hasNextSlide,
  hasNextStep,
  disableHotkeys = false,
  ...rest
}: NextProps) => {
  const translate = useTranslate();

  return (
    <>
      <ToolbarButton
        {...rest}
        ariaLabel={translate('toolbar.next_slide')}
        id="next-slide"
        data-testid="toolbar-next"
        icon={ArrowRightIcon}
        disabled={!hasNextSlide}
        label={translate('toolbar.next_slide')}
        onClick={() => nextSlide('Navigation button')}
      />
      {!disableHotkeys && hasNext && (
        <>
          <Hotkey
            preventDefault
            keyCode="pagedown"
            action={() => next('Hotkey pagedown')}
          />
          <Hotkey
            preventDefault
            keyCode="arrowright"
            action={() => {
              next('Hotkey arrowright');
            }}
          />
          {hasNextStep && (
            <Hotkey
              preventDefault
              keyCode="arrowdown"
              action={() => nextStep('Hotkey arrowdown')}
            />
          )}
        </>
      )}
    </>
  );
};
