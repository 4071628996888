// This file is automatically generated. DO NOT EDIT.

import { type Reactions } from '../../../../types/response/Reactions';
import { type ReactionTotal } from '../../../../types/response/ReactionTotal';
import { fromSnakeKeys } from '@api/internal';

export interface GetReactionResultsResponse {
  /** Recived reactions */
  reactions: Array<Reactions>;
  /** Total reactions results */
  total: ReactionTotal;
}

export async function getReactionResultsResponse(
  response: Response,
): Promise<GetReactionResultsResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as GetReactionResultsResponse;
}
